var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "psi-dialog",
    {
      attrs: {
        icon: "mdi-view-split-horizontal",
        title: "Split Pages",
        "heading-color": "secondary",
        "btn-color": "primary"
      },
      on: {
        close: function($event) {
          return _vm.$emit("close")
        },
        save: _vm.handleSave
      }
    },
    [
      _c("v-divider", { staticClass: "mb-2" }),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              staticClass: "d-flex justify-end",
              attrs: { cols: "3", offset: "9" }
            },
            [
              _c("psi-action-drop-down", {
                attrs: {
                  heading: "File Categories",
                  "btn-label": "Add Page Range",
                  items: _vm.items
                },
                on: { action: _vm.addFileCategory }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._l(_vm.files, function(file, index) {
        return [
          _c("psi-split-pages", {
            key: index,
            attrs: {
              pages: file.pages,
              label: file.label,
              required: file.required
            },
            on: {
              "update:pages": function($event) {
                return _vm.updateFilePages(file, $event)
              },
              remove: function($event) {
                return _vm.removeFile(file)
              }
            }
          })
        ]
      }),
      _c(
        "v-snackbar",
        {
          attrs: {
            top: "",
            timeout: "3000",
            color: "secondary",
            dark: "",
            dismiss: ""
          },
          model: {
            value: _vm.snackbar,
            callback: function($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar"
          }
        },
        [
          _c(
            "div",
            { staticClass: "d-flex align-center" },
            [
              _c("span", [_vm._v(_vm._s(_vm.message))]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { text: "", small: "" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      _vm.snackbar = false
                    }
                  }
                },
                [_c("v-icon", { attrs: { small: "" } }, [_vm._v("mdi-close")])],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }