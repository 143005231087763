<template>
    <psi-dialog
        icon="mdi-view-split-horizontal"
        title="Split Pages"
        heading-color="secondary"
        btn-color="primary"
        @close="$emit('close')"
        @save="handleSave"
    >
        <v-divider class="mb-2"></v-divider>
        <v-row>
            <v-col cols="3" offset="9" class="d-flex justify-end">
                <psi-action-drop-down
                    heading="File Categories"
                    btn-label="Add Page Range"
                    :items="items"
                    @action="addFileCategory"
                >
                </psi-action-drop-down>
            </v-col>
        </v-row>

        <template v-for="(file, index) in files">
            <psi-split-pages
                :key="index"
                :pages="file.pages"
                :label="file.label"
                :required="file.required"
                @update:pages="updateFilePages(file, $event)"
                @remove="removeFile(file)"
            ></psi-split-pages>
        </template>
        <v-snackbar
            v-model="snackbar"
            top
            timeout="3000"
            color="secondary"
            dark
            dismiss
        >
            <div class="d-flex align-center">
                <span>{{ message }}</span>
                <v-spacer></v-spacer>
                <v-btn text small @click.stop="snackbar = false"
                    ><v-icon small>mdi-close</v-icon></v-btn
                >
            </div>
        </v-snackbar>
    </psi-dialog>
</template>
<script>
export default {
    name: "applicant-split-pages",
    components: {
        PsiSplitPages: () => import("./PsiSplitPages"),
    },
    props: {},
    data() {
        return {
            snackbar: false,
            message: "",
            items: [
                {
                    icon: "mdi-clipboard-account",
                    title: "Application",
                    category: "Application",
                },
                {
                    icon: "mdi-draw",
                    title: "Authorization",
                    category: "Authorization",
                },
                {
                    icon: "mdi-card-account-details",
                    title: "Identification",
                    category: "Identification",
                },
                {
                    icon: "mdi-briefcase-account",
                    title: "Income Verification",
                    category: "Income",
                },
                {
                    icon: "mdi-account-question",
                    title: "Other",
                    category: "Other",
                },
            ],
            files: [
                {
                    label: "Application",
                    category: "Application",
                    id: this.$uuid.v4(),
                    required: false,
                    startPage: 0,
                    numPages: 0,
                    pages: {},
                },
                {
                    label: "Authorization",
                    category: "Authorization",
                    id: this.$uuid.v4(),
                    required: false,
                    startPage: 0,
                    numPages: 0,
                    pages: {},
                },
                // {
                //     label: "Identification",
                //     category: "Identification",
                //     id: this.$uuid.v4(),
                //     required: false,
                //     startPage: 0,
                //     numPages: 0,
                //     pages: {},
                // },
                // {
                //     label: "Income Verification",
                //     category: "Income",
                //     id: this.$uuid.v4(),
                //     required: false,
                //     startPage: 0,
                //     numPages: 0,
                //     pages: {},
                // },
                {
                    label: "Other",
                    category: "Other",
                    id: this.$uuid.v4(),
                    startPage: 0,
                    numPages: 0,
                    required: false,
                    pages: {},
                },
            ],
        };
    },
    methods: {
        updateFilePages(file, pages) {
            if (Object.keys(pages).length > 0) {
                const index = this.files.findIndex((f) => f.id === file.id);
                file.pages = pages;

                if (
                    !isNaN(parseInt(pages.from)) &&
                    !isNaN(parseInt(pages.to))
                ) {
                    file.numPages =
                        parseInt(pages.to) - parseInt(pages.from) + 1;
                    file.startPage = parseInt(pages.from);
                }
                this.files.splice(index, 1, file);
            }
        },
        addFileCategory(item) {
            this.files.push({
                label: item.title,
                category: item.category,
                id: this.$uuid.v4(),
                required: false,
                startPage: 0,
                numPages: 0,
                pages: {},
            });
            this.message = `${item.title} page range added`;
            this.snackbar = true;
        },
        removeFile(file) {
            const index = this.files.findIndex((f) => f.id === file.id);
            this.files.splice(index, 1);
        },
        handleSave() {
            this.$emit(
                "split:pages",
                this.files
                    .filter((f) => f.startPage > 0)
                    .map((f) => {
                        const { category, id, numPages, startPage } = f;
                        return { category, id, numPages, startPage };
                    })
            );
        },
    },
};
</script>

<style scoped>
</style>